import React, { useEffect, useState } from 'react';
import { LayoutContextProvider } from '../providers/LayoutProvider';
import { SearchContextProvider } from '../providers/SearchProvider';

export default ({ element }) => {
    return <Inner>{element}</Inner>;
};

const Inner = ({ children }) => {
    const [inlineAnchor, setInlineAnchor] = useState<string>(``);
    const [anchorTagEls, setAnchorTagEls] = useState<HTMLAnchorElement[]>(null);
    const [sidebarExpanded, setSidebarExpanded] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [currentSubmenu, setCurrentSubmenu] = useState(``);
    const [quizResults, setQuizResults] = useState<{
        [key: string]: string | number | boolean;
    }>(null);
    const [urlParams, setUrlParams] = useState(``);
    const [nodeLookup, setNodeLookup] = useState<
        { nodeId: number; path: string }[]
    >([]);
    const [inputProspect, setInputProspect] = useState({});

    useEffect(() => {
        try {
            const savedResults = localStorage.getItem(`quizResults`);
            if (savedResults) {
                const json = JSON.parse(savedResults);
                setQuizResults(json);
            } else {
                setQuizResults({ none: true });
            }
        } catch {
            console.warn(`Unable to retrieve quiz results.`);
            setQuizResults({ none: true });
        }
    }, []);

    return (
        <LayoutContextProvider
            value={{
                sidebarExpanded,
                setSidebarExpanded,
                currentSubmenu,
                setCurrentSubmenu,
                quizResults,
                setQuizResults,
                urlParams,
                setUrlParams,
                nodeLookup,
                setNodeLookup,
                searchOpen,
                setSearchOpen,
                inlineAnchor,
                setInlineAnchor,
                anchorTagEls,
                setAnchorTagEls,
                inputProspect,
                setInputProspect,
            }}
        >
            <SearchContextProvider>{children}</SearchContextProvider>
        </LayoutContextProvider>
    );
};
