import React, { createContext, useContext, useRef } from 'react';

export const LayoutContext = createContext({
    sidebarExpanded: false,
    currentSubmenu: ``,
    quizResults: null,
    setQuizResults: (quizResults: {
        [key: string]: string | number | boolean;
    }) => {
        //
    },
    setCurrentSubmenu: (submenu: string) => {
        //
    },
    setSidebarExpanded: (expanded: boolean) => {
        //
    },
    searchOpen: true,
    setSearchOpen: (expanded: boolean) => {
        //
    },
    urlParams: ``,
    setUrlParams: (params: string) => {
        //
    },
    nodeLookup: [],
    setNodeLookup: (nodeMap: { nodeId: number; path: string }[]) => {
        //
    },
    inlineAnchor: ``,
    setInlineAnchor: (val: string) => void null,
    inputProspect: null,
    setInputProspect: (inputProspect: {
        [key: string]: string | number | boolean;
    }) => null,
});

export const LayoutContextProvider = ({ value, children }) => {
    return (
        <LayoutContext.Provider
            value={{
                ...value,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};

export const useLayoutContext = () => useContext(LayoutContext);
